<template>
  <div class="content_body InventoryProductInbound">
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" size="small" :model="searchForm" @keyup.enter.native="searchDataList">
            <el-form-item v-if="EntityList.length>1" label="仓库/门店">
              <el-select v-model="searchForm.EntityID" clearable filterable placeholder="请选择仓库/门店" :default-first-option="true" @change="searchDataList">
                <el-option v-for="item in EntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品">
              <el-input v-model="searchForm.ProductName" placeholder="请输入产品名称、别名" clearable @keyup.enter.native="searchDataList" @clear="searchDataList"></el-input>
            </el-form-item>
            <el-form-item label="入库类型">
              <el-select v-model="searchForm.InventoryType" clearable filterable placeholder="请选择入库类型" :default-first-option="true" @change="searchDataList" @clear="searchDataList">
                <el-option v-for="item in OutType" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="制单日期">
              <el-date-picker v-model="searchForm.DateTime" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" clearable @change="searchDataList"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchDataList" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="2" class="text_right">
          <el-button type="primary" @click="addInventoryProductInbound" size="small" v-prevent-click>新建入库</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table class="martp_10" size="small" :data="data_list" tooltip-effect="light">
      <el-table-column prop="ID" label="单据号"></el-table-column>
      <el-table-column prop="EntityName" label="仓库/门店"></el-table-column>
      <el-table-column prop="InventoryType" label="入库类型"></el-table-column>
      <el-table-column prop="CreatedOn" label="制单时间"></el-table-column>
      <el-table-column prop="CreatedByName" label="操作人"></el-table-column>
      <el-table-column prop="Remark" label="备注" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="80px">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="showInventoryProductInbound(scope.row)" v-prevent-click>详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="InventoryProductInboundHandleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>

    <!-- 新建产品入库  -->
    <el-dialog custom-class="addInboundDialogClass" title="产品入库 - 其他入库" :visible.sync="addInventoryProductInboundDialogVisible" width="1000px" @close="closeAddInventoryProductInbound">
      <div class="tip">基本信息</div>
      <el-form class="addInboundDialogClassInfoFrom" :inline="true" :inline-message="true" label-width="100px" size="small" :model="InboundFrom" :rules="InboundFromRules" ref="InboundFromRef">
        <el-row>
          <el-col :span="12">
            <el-form-item label="仓库/门店：" prop="EntityID">
              <el-select size="small" value-key="ID" v-model="InboundFrom.EntityName" filterable placeholder="请选择仓库/门店" @change="handleSelectEntity">
                <el-option value-key="ID" v-for="item in EntityList" :key="item.ID" :label="item.EntityName" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="入库时间：" prop="InDate">
              <el-date-picker v-model="InboundFrom.InDate" placeholder="选择出库时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="InboundFrom.default_time">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="martp_10">
          <el-col :span="12">
            <el-form-item label="备注信息：">
              <el-input v-model="InboundFrom.Remark" placeholder="请输入备注信息" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="tip marbm_10 martp_10">产品明细</div>
        <el-row>
          <el-col :span="24">
            <el-button type="primary" size="small" @click="addProducts">新增产品</el-button>
            <el-button type="danger" size="small" @click="removeMultipleProduct" :disabled="removeDisabled">删除产品</el-button>
          </el-col>
        </el-row>
        <el-table empty-text="暂无产品" size="small" class="martp_10" max-height="400px" :data="InboundFrom.Detail" @selection-change="removeHandleChangeSelectProduct">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="产品名称" prop="ProductName">
            <template slot-scope="scope">
              <el-form-item :show-message="false" :prop="'Detail.' + scope.$index + '.ProductID'" :rules="InboundFromRules.ProductID">
                <el-select v-model="scope.row.ProductID" size="small" filterable remote reserve-keyword v-loadmore="loadMoreProduct" placeholder="请选择产品" :remote-method="searchProductListMethod" :loading="productLoading" @change="(val)=>handleSelectProduct(val,scope.row)" @focus="selectFocus" @clear="clearSelectProduct(scope.row)" :default-first-option="true" popper-class="InventoryProductInbound_custom_popper_class">
                  <el-option v-for="item in ProductList" :key="item.ID" :label="item.ProductName" :value="item.ID" :disabled="item.IsLock">
                    <div class="dis_flex flex_dir_column pad_5_0">
                      <div>
                        <span>{{ item.ProductName }}</span>
                        <span class="color_gray marlt_5 font_12" v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag v-if="item.IsLock" size="mini" type="warning" effect="dark">{{'盘点锁定'}}</el-tag>
                      </div>
                      <div :class="item.ID == scope.row.ID?'font_12 dis_flex  flex_x_between' : 'color_gray font_12 dis_flex flex_x_between'">
                        <span class="">{{ item.PCategoryName }}</span>
                        <span v-if="item.Specification">{{ item.Specification }}</span>
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>

          </el-table-column>
          <el-table-column prop="Specification" label="产品规格">
            <template slot-scope="scope">{{scope.row.Specification}}</template>
          </el-table-column>
          <el-table-column label="入库单位" prop="UnitName">

            <template slot-scope="scope">
              <el-form-item :show-message="false" :prop="'Detail.' + scope.$index + '.UnitName'" :rules="InboundFromRules.UnitID">
                <el-select value-key="UnitID" v-model="scope.row.UnitName" size="small" filterable placeholder="请选择单位" @change="(val)=>handleSelectProductUnit(val,scope.row)" :default-first-option="true">
                  <el-option v-for="item in scope.row.Units" :key="item.UnitID" :label="item.UnitName" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </template>

          </el-table-column>
          <el-table-column label="入库数量" prop="Quantity">
            <template slot-scope="scope">
              <el-form-item :show-message="false" :prop="'Detail.' + scope.$index + '.Quantity'" :rules="InboundFromRules.Quantity">
                <el-input class="input_type" v-model="scope.row.Quantity" size="small" placeholder="请输入入库数量" validate-event v-enter-number2 v-enterInt min="0" type="number" @input="changeInboundQuantity(scope.row)"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="最小包装数量">
            <template slot-scope="scope" v-if="scope.row.Quantity">{{scope.row.Quantity * scope.row.miniAmount}} {{scope.row.miniUnitName}}</template>
          </el-table-column>
        </el-table>

      </el-form>

      <div slot="footer">
        <el-button @click="addInventoryProductInboundDialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="saveInventoryProductInboundClick()" :loading="InboundLoading" size="small" v-prevent-click>确认入库</el-button>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog custom-class="addInboundDialogClass" title="入库详情" :visible.sync="InboundInfoDialogVisible" width="1000px">
      <div class="tip">基本信息</div>
      <el-form class="addInboundDialogClassInfoFrom" :inline="true" :inline-message="true" label-width="100px" size="small" :model="InboundInfo">

        <el-row>
          <el-col :span="8">
            <el-form-item label="单据号：">{{InboundInfo.ID}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库/门店：">{{InboundInfo.EntityName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人：">{{InboundInfo.CreatedByName}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="入库类型：">{{InboundInfo.InventoryType}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库时间：">{{InboundInfo.InDate}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="制单时间：">{{InboundInfo.CreatedOn}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注信息：">{{InboundInfo.Remark}}</el-form-item>
          </el-col>
        </el-row>

        <div class="tip marbm_10">产品明细</div>
        <el-table size="small" max-height="450px" :data="InboundInfo.Detail">
          <el-table-column label="产品" prop="ProductName">
            <template slot-scope="scope">
              <div>{{scope.row.ProductName}}<span v-if="scope.row.Alias" class="font_12 color_999">({{scope.row.Alias}})</span></div>
              <div v-if="scope.row.PackageName" class="font_12 color_999">所属套餐： {{scope.row.PackageName}}</div>
              <div v-if="scope.row.Specification" class="font_12 color_999">规格：{{scope.row.Specification}}</div>
            </template>
          </el-table-column>
          <el-table-column label="产品分类" prop="PCategoryName">
          </el-table-column>
          <el-table-column label="入库数量" prop="Quantity">
            <template slot-scope="scope">
              <div>{{scope.row.Quantity}} {{scope.row.UnitName}}</div>
              <div class="color_gray font_12">最小包装数量：{{scope.row.MinimumUnitQuantity}} {{scope.row.MinimumUnitName}}</div>
            </template>
          </el-table-column>
        </el-table>

      </el-form>
    </el-dialog>

  </div>
</template>

<script>
// import permission from "@/components/js/permission.js";

import API from "@/api/PSI/Inventory/inventoryProductInbound";
import APIStorage from "@/api/PSI/Purchase/storage";
// import APIInquire from  "@/api/PSI/Inventory/Inquire";
import APIInventory from "@/api/PSI/Inventory/inventoryDetail";

import dateUtil from "@/components/js/date";
import loginVue from "../../login/login.vue";
var Enumerable = require("linq");

export default {
  name: "InventoryProductInbound",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      addInventoryProductInboundDialogVisible: false,
      selectProductDialogVisible: false,
      InboundLoading: false,
      InboundInfoDialogVisible: false,
      productLoading: false,

      // 列表筛选条件
      searchForm: {
        EntityID: "",
        ProductName: "",
        DateTime: "",
        InventoryType: "",
      },
      OutType: ["采购入库", "要货入库", "调拨入库","退货入库", "其他入库","加盟商采购入库"],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() ? true : false;
        },
      },
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息
      InboundFrom: {
        EntityID: "",
        EntityName: "",
        InDate: dateUtil.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss"),
        default_time: dateUtil.formatDate.format(new Date(), "hh:mm:ss"),
        Remark: "",
        Detail: [],
      },
      InboundFromRules: {
        EntityID: [
          {
            required: true,
            message: "请选择出库仓库/门店",
            trigger: ["blur", "change"],
          },
        ],
        InDate: [
          {
            required: true,
            message: "请选择出库日期",
            trigger: ["blur", "change"],
          },
        ],
        Quantity: [{ required: true, trigger: ["blur", "change"] }],
        ProductID: [{ required: true, trigger: ["blur", "change"] }],
        UnitID: [{ required: true, trigger: ["blur", "change"] }],
      },

      EntityList: [], //仓库列表
      removeDisabled: true,
      ProductList: [],
      ProductListTotal: 0,
      productPageNum: 1,
      multipleProducts: [],
      selectProductList: [],
      data_list: [],
      InboundInfo: "",
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**  搜索  */
    searchDataList() {
      let that = this;
      that.paginations.page = 1;
      that.get_list_inventoryProductInbound_netWork();
    },
    /**  分页切换  */
    InventoryProductInboundHandleCurrentChange(page) {
      let that = this;
      that.paginations.page = page;
      that.get_list_inventoryProductInbound_netWork();
    },
    /**  添加入库  */
    addInventoryProductInbound() {
      let that = this;
      that.InboundFrom = {
        EntityID: "",
        EntityName: "",
        InDate: dateUtil.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss"),
        default_time: dateUtil.formatDate.format(new Date(), "hh:mm:ss"),
        Remark: "",
        Detail: [],
      };

      if (that.EntityList.length == 1) {
        let entityItem = that.EntityList[0];
        that.InboundFrom.EntityID = entityItem.ID;
        that.InboundFrom.EntityName = entityItem.EntityName;
      }
      that.addInventoryProductInboundDialogVisible = true;
    },
    /** 查看详情   */
    showInventoryProductInbound(row) {
      let that = this;
      let parmas = {
        ID: row.ID,
        InventoryType: row.InventoryType,
      };
      that.get_info_inventoryProductInbound_netWork(parmas);
    },
    /**  关闭添加弹窗回调  */
    closeAddInventoryProductInbound() {
      let that = this;
      that.$refs["InboundFromRef"].clearValidate();
      if (that.$refs.multipleTable) {
        that.$refs.multipleTable.clearSelection();
      }
    },
    /**  选择入库仓库  */
    handleSelectEntity(row) {
      let that = this;
      that.InboundFrom.EntityID = row.ID;
      that.InboundFrom.Detail = [];
    },
    // /**   修改入库数量 */
    changeInboundQuantity(row) {
      row.Quantity = Math.floor(row.Quantity);
    },

    /**  新增产品  */
    addProducts() {
      let that = this;
      that.searchProductName = "";
      that.$refs["InboundFromRef"].validateField("EntityID", (valid) => {
        if (!valid) {
          that.InboundFrom.Detail.push({
            ProductID: "", // ID
            ProductName: "", // 名称
            Alias: "", // 别名
            Specification: "", // 规格
            UnitID: "", // 单位ID
            UnitName: "", // 单位名称
            Units: [], // 单位集合
            Quantity: "", // 申请数量
            StockQuantity: "", // 库存
          });
        }
      });
    },
    /**  选择将要删除的产品  */
    removeHandleChangeSelectProduct(selection) {
      this.multipleProducts = selection;
      if (this.multipleProducts.length > 0) {
        this.removeDisabled = false;
      } else {
        this.removeDisabled = true;
      }
    },
    /**  删除所选产品  */
    removeMultipleProduct() {
      var that = this;
      if (that.multipleProducts.length > 0) {
        for (var i = 0; i < that.InboundFrom.Detail.length; i++) {
          that.multipleProducts.forEach(function (item) {
            if (that.InboundFrom.Detail[i] == item) {
              that.InboundFrom.Detail.splice(i, 1);
            }
          });
        }
      }
    },

    /**  下拉选择产品  */
    handleSelectProduct(item_ID, row) {
      var that = this;
      // let item = Enumerable.from(that.ProductList).firstOrDefault(i=>{return item_ID == i.ProductID},{})

      // row.ProductID = item.ProductID;
      // row.Units = item.Unit;
      // row.Specification = item.Specification;

      row.Quantity = "";
      let item = Enumerable.from(that.ProductList).firstOrDefault((i) => {
        return item_ID == i.ID;
      }, {});
      row.ProductID = item.ID;
      row.ProductName = item.ProductName;
      row.Units = item.Unit;
      row.Specification = item.Specification;

      let defaultUnit = Enumerable.from(item.Unit).firstOrDefault((i) => {
        return i.IsDefautSendReceive;
      }, -1);
      let miniUnit = Enumerable.from(item.Unit).firstOrDefault((i) => {
        return i.IsMinimumUnit;
      }, -1);
      if (defaultUnit != -1) {
        row.UnitID = defaultUnit.UnitID;
        row.UnitName = defaultUnit.UnitName;
        row.miniAmount = defaultUnit.Amount;
      }
      if (miniUnit != -1) {
        row.miniUnitName = miniUnit.UnitName;
        row.MinimumUnitID = miniUnit.UnitID;
      }
    },
    /** 获取焦点   */
    selectFocus() {
      let that = this;
      that.ProductList = [];
      that.searchProductListMethod("");
    },
    /** 远程搜索产品   */
    searchProductListMethod(query) {
      var that = this;
      that.productPageNum = 1;
      that.ProductList = [];
      that.get_stock_list_entityProductListNetwork(query);
    },
    /**  加载更多产品  */
    loadMoreProduct() {
      var that = this;
      if (that.ProductListTotal > that.ProductList.length) {
        that.productPageNum++;
        that.get_stock_list_entityProductListNetwork();
      }
    },
    /**   选择单位 */
    handleSelectProductUnit(val, row) {
      let that = this;
      row.Quantity = "";
      row.UnitID = val.UnitID;
      row.UnitName = val.UnitName;
      row.miniAmount = val.Amount;
    },

    /**  保存产品入库  */
    saveInventoryProductInboundClick() {
      let that = this;
      if (that.InboundFrom.Detail.length == 0) {
        that.$message.error({
          message: "请选择入库产品",
          duration: 2000,
        });
        return;
      }
      that.$refs["InboundFromRef"].validate((valid) => {
        if (valid) {
          let Products = Enumerable.from(that.InboundFrom.Detail)
            .select((i) => ({
              ProductID: i.ProductID,
              UnitID: i.UnitID,
              Quantity: i.Quantity,
              MinimumUnitID: i.MinimumUnitID,
              MinimumUnitQuantity:
                parseFloat(i.Quantity) * parseFloat(i.miniAmount),
            }))
            .toArray();
          let params = {
            EntityID: that.InboundFrom.EntityID,
            InDate: that.InboundFrom.InDate,
            Remark: that.InboundFrom.Remark,
            Detail: Products,
          };
          that.get_create_inventoryProductInbound_netWork(params);
        }
      });
    },

    /** ++++++++++++++ ============================================= +++++++++++++ */

    /**  仓库列表  */
    getStorageEntityNetwork: function () {
      var that = this;
      var params = {};
      APIStorage.getpurchaseStorageEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.EntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },

    /** 查询 产品 库存列表 列表  */
    get_stock_list_entityProductListNetwork: function (searchProductName) {
      var that = this;
      var params = {
        PageNum: that.productPageNum,
        ProductName: searchProductName,
        EntityID: that.InboundFrom.EntityID,
      };
      APIInventory.get_stock_list_entityProductListAll(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.ProductListTotal = res.Total;
            that.ProductList.push.apply(that.ProductList, res.List);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },

    /**   10.1.产品入库列表   */
    get_list_inventoryProductInbound_netWork: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        // ID: that.searchForm.ID,
        EntityID: that.searchForm.EntityID,
        ProductName: that.searchForm.ProductName,
        StartDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[0],
        EndDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[1],
        InventoryType: that.searchForm.InventoryType,
      };
      API.get_list_inventoryProductInbound(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.data_list = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**   10.2.产品入库详情    */
    get_info_inventoryProductInbound_netWork: function (params) {
      var that = this;
      that.loading = true;
      // var params = {
      //   ID: ID
      // };
      API.get_info_inventoryProductInbound(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.InboundInfo = res.Data;
            that.InboundInfoDialogVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  10.3.产品入库   */
    get_create_inventoryProductInbound_netWork: function (params) {
      var that = this;
      API.get_create_inventoryProductInbound(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.addInventoryProductInboundDialogVisible = false;
            that.searchDataList();
            that.$message.success({
              message: "操作成功",
              duration: 2000,
            });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
  },
  /**  实例被挂载后调用  */
  mounted() {
    var that = this;
    // var date = new Date(),
    // y = date.getFullYear(),
    // m = date.getMonth();
    // that.searchForm.DateTime = [dateUtil.formatDate.format(new Date(y, m, 1),"YYYY-MM-DD"),dateUtil.formatDate.format(new Date(),"YYYY-MM-DD")];
    that.searchDataList();
    that.getStorageEntityNetwork();
  },
};
</script>

<style lang="scss">
.InventoryProductInbound {
  .addInboundDialogClass {
    .addInboundDialogClassInfoFrom {
      .el-form-item__label {
        font-size: 13px !important;
      }
      .el-form-item__content {
        font-size: 13px !important;
      }
      .el-form-item {
        margin-bottom: 0px;
      }

      .el-input__inner {
        padding-right: 0;
      }
    }
  }
  .IsLockProduct_list_back {
    background-color: #edf2fc;
    cursor: not-allowed;
  }
  .IsLockProduct_list_back:hover > td {
    background-color: #edf2fc !important;
  }
}

.InventoryProductInbound_custom_popper_class {
  .el-select-dropdown__item {
    line-height: normal;
    height: auto;
  }
}
</style>
